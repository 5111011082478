import * as THREE from 'three'

import Gl from '../Gl'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

export default class Assets {
  constructor() {
    this.gl = new Gl()

    // Loaders
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath('/draco/')
    this.rgbeLoader = new RGBELoader(this.gl.loadingManager)
    this.gltfLoader = new GLTFLoader(this.gl.loadingManager)
    this.gltfLoader.setDRACOLoader(this.dracoLoader)

    // Models
    this.models = {
      helmet: null,
      fullBody: null,
    }

    // Textures
    this.textures = {
      helmet: {
        base: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/helmet/helmet__base.png'),
        logos: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/helmet/helmet__logos.png'),
        mask: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/helmet/helmet__mask.png'),
        normal: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/helmet/Norris_Helmet_mat_Normal.png'),
        roughness: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/helmet/Norris_Helmet_mat_Roughness.png'),
        metallic: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/helmet/Norris_Helmet_mat_Metallic.png'),
      },
      glass: {
        base: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/glass/Norris_Glass_mat_BaseColor.png'),
        normal: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/glass/Norris_Glass_mat_Normal.png'),
        roughness: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/glass/Norris_Glass_mat_Roughness.png'),
        metallic: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/glass/Norris_Glass_mat_Metallic.png'),
      },
      plastic: {
        matcap: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/plastic/plastic__matcap.png'),
      },
      logo: {
        alpha: new THREE.TextureLoader(this.gl.loadingManager).load('./textures/logo/lando__logo--alpha.png'),
      },
    }

    this.textures.helmet.base.flipY = false
    this.textures.helmet.normal.flipY = false
    this.textures.helmet.roughness.flipY = false
    this.textures.helmet.metallic.flipY = false
    this.textures.helmet.mask.flipY = false
    this.textures.helmet.logos.flipY = false

    this.textures.glass.base.flipY = false
    this.textures.glass.normal.flipY = false
    this.textures.glass.roughness.flipY = false
    this.textures.glass.metallic.flipY = false

    this.textures.plastic.matcap.flipY = false

    this.textures.logo.alpha.minFilter = THREE.NearestFilter

    // HDRI
    this.hdri = null

    // Load
    this.load()
  }

  load() {
    this.gltfLoader.load('/3d/helmet.glb', (_gltf) => {
      // _gltf.scene.scale.set(2)
      this.models.helmet = _gltf
      this.models.helmet.scene.frustumCulled = false
    })

    this.gltfLoader.load('/3d/full-body.glb', (_gltf) => {
      // _gltf.scene.scale.set(2)
      this.models.fullBody = _gltf

      _gltf.scene.traverse((object) => {
        object.frustumCulled = false
      })
    })

    this.rgbeLoader.load('./hdri/studio_small_08_1k--green.hdr', (_texture) => {
      this.hdri = _texture
    })
  }
}
