import 'lenis/dist/lenis.css'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from 'lenis'

import Gl from '../Gl'

export default class Animations {
  constructor() {
    gsap.registerPlugin(ScrollTrigger)

    this.gl = new Gl()
    this.lenis = new Lenis()

    this.lenis.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time) => {
      this.lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)

    /* 
      Animations
    */
    this.setHeroScrollAnimation()
    this.setVictoryScrollAnimation()
    this.setWeightTransitions()
  }

  setHeroScrollAnimation() {
    /* 
      Timeline
    */
    let tl = new gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('[data-scroll-animation="hero"]'),
        scrub: true,
        // markers: true,
        start: 'top top',
        end: 'bottom bottom',
      },
    })

    /* 
      Animations
    */
    // tl.fromTo(
    //   this.gl.camera.instance.position,
    //   {
    //     z: 0.5,
    //   },
    //   {
    //     z: 2,
    //     ease: 'none',
    //     duration: 1,
    //   },
    //   0
    // )

    // tl.fromTo(
    //   this.gl.camera.data.lookAt.current,
    //   {
    //     x: this.gl.camera.data.lookAt.from.x,
    //     y: this.gl.camera.data.lookAt.from.y,
    //     z: this.gl.camera.data.lookAt.from.z,
    //   },
    //   {
    //     x: this.gl.camera.data.lookAt.to.x,
    //     y: this.gl.camera.data.lookAt.to.y,
    //     z: this.gl.camera.data.lookAt.to.z,
    //     ease: 'none',
    //     duration: 1,
    //     onUpdate: () => {
    //       this.gl.camera.instance.lookAt(this.gl.camera.data.lookAt.current)
    //     },
    //   },
    //   0
    // )

    tl.fromTo(
      this.gl.world.animationGroup.position,
      {
        y: -5.2,
        z: this.gl.camera.instance.position.z - 1.5,
      },
      {
        y: -4.5,
        z: this.gl.camera.instance.position.z - 3.45,
        ease: 'none',
      },
      0
    )

    tl.fromTo(
      this.gl.world.animationGroup.rotation,
      {
        y: -Math.PI,
      },
      {
        y: 0,
        ease: 'none',
      },
      0
    )
  }

  setVictoryScrollAnimation() {
    /* 
      Timeline
    */
    let tl = new gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('[data-scroll-animation="victory"]'),
        scrub: true,
        // markers: true,
        // start: 'top top',
        end: 'bottom bottom',
      },
    })

    /* 
      Animations
    */
    tl.fromTo(
      this.gl.world.animationGroup.position,
      {
        z: this.gl.camera.instance.position.z - 3.45,
      },
      {
        z: this.gl.camera.instance.position.z - 4.5,
        ease: 'none',
      },
      0
    )

    tl.fromTo(
      this.gl.world.animationGroup.rotation,
      {
        y: 0,
      },
      {
        y: Math.PI * 2,
        ease: 'none',
      },
      0
    )
  }

  setWeightTransitions() {
    /* 
      From Idle to Victory
    */
    let fromIdleToVictory = new gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector('[data-scroll-animation="victory"]'),
        scrub: true,
        // markers: true,
        // start: 'top top',
        end: `start+=${this.gl.sizes.height} bottom`,
        onUpdate: () => {
          this.gl.world.fullBody.animations.idle.animation.setEffectiveWeight(this.gl.world.fullBody.animations.idle.weight)
          this.gl.world.fullBody.animations.victory.animation.setEffectiveWeight(this.gl.world.fullBody.animations.victory.weight)
        },
        onEnter: () => {
          this.gl.world.fullBody.animations.victory.animation.reset()
        },
      },
    })

    // Idle
    fromIdleToVictory.fromTo(
      this.gl.world.fullBody.animations.idle,
      {
        weight: 1,
      },
      {
        weight: 0,
      },
      0
    )

    // Victory
    fromIdleToVictory.fromTo(
      this.gl.world.fullBody.animations.victory,
      {
        weight: 0,
      },
      {
        weight: 1,
      },
      0
    )
  }
}
