import gsap from 'gsap'
import { Vector2, MathUtils, TextureUtils } from 'three'
import { interpolate } from 'polymorph-js'

import Gl from '../Gl'
import Mathematics from '../Utils/Mathematics'

export default class Video {
  constructor() {
    this.gl = new Gl()
    this.mathematics = new Mathematics()

    this.params = {
      progress: 0,
      duration: {
        open: 0.75,
        close: 0.5,
      },
    }

    this.selectors = {
      scale: document.querySelector('.video-overlay__video-scale'),
      container: document.querySelector('.video-overlay__video-container'),
      wrapper: document.querySelector('.video-overlay__video-wrapper'),
      video: {
        thumbnail: document.querySelector('.video-overlay__video--thumbnail'),
        player: document.querySelector('.video-overlay__video--player'),
      },
      canvas: document.querySelector('.gl'),
      background: document.querySelector('.video-overlay__background'),
      closeBtn: document.querySelector('.video-overlay__close-btn'),
      play: document.querySelector('.video-overlay__play'),
      pause: document.querySelector('.video-overlay__pause'),
      progressBar: document.querySelector('.video-overlay__progress-bar'),
      progressPath: document.querySelector('#video-overlay__progress__path'),
      progressCircle: {
        group: document.querySelector('#video-overlay__progress-circle'),
        circle: document.querySelector('#video-overlay__progress-circle__circle'),
      },
    }

    this.flags = {
      isOpened: false,
      isPlaying: true,
      isPreviousHover: false,
      isTransitioning: false,
    }

    this.progressPathLength = 0

    // this.default = {
    //   progress: 1
    // }
    // document.querySelector('#video-overlay__mask__path-visible').setAttribute('d', this.mixer(0.25))
    // console.log(this.mixer(1))

    /* 
      Events
    */
    document.addEventListener('click', this.handleOpen.bind(this))
    this.selectors.background.addEventListener('click', this.handleClose.bind(this))
    this.selectors.closeBtn.addEventListener('click', this.handleClose.bind(this))
    this.selectors.video.player.addEventListener('click', this.handlePause.bind(this))
    this.selectors.progressBar.addEventListener('mousedown', this.handleProgressDown.bind(this))
    this.selectors.progressBar.addEventListener('mousemove', this.handleProgressMove.bind(this))
    this.selectors.progressBar.addEventListener('mouseup', this.handleProgressUp.bind(this))
    // this.selectors.video.player.addEventListener('timeupdate', this.updateProgress.bind(this))

    /* 
      Init Functions
    */
    this.setProgress()
  }

  setProgress() {
    this.progressPathLength = this.selectors.progressPath.getTotalLength()

    this.selectors.progressPath.style.strokeDasharray = this.progressPathLength
    this.selectors.progressPath.style.strokeDashoffset = this.progressPathLength
  }

  handleProgressDown(_e) {
    this.selectors.video.player.pause()
  }

  handleProgressMove(_e) {
    if (this.gl.mouse.isMouseHolding) {
      let position = this.selectors.video.player.duration * (_e.offsetX / this.selectors.progressBar.clientWidth)

      this.selectors.video.player.currentTime = position
    }
  }

  handleProgressUp(_e) {
    if (this.flags.isPlaying) {
      this.selectors.video.player.play()
    }
  }

  handlePause() {
    if (this.flags.isPlaying) {
      this.selectors.video.player.pause()

      this.flags.isPlaying = false

      this.selectors.pause.style.visibility = 'visible'
    } else {
      this.selectors.video.player.play()

      this.flags.isPlaying = true

      this.selectors.pause.style.visibility = 'hidden'
    }
  }

  handleOpen() {
    if (!this.gl.world.logo.flags.isHovering) return
    if (this.flags.isTransitioning) return
    if (this.flags.isOpened) return

    this.flags.isOpened = true

    this.selectors.progressCircle.group.setAttribute('visibility', 'visible')

    gsap.to(this.params, {
      progress: 1,
      duration: this.params.duration.open,
      ease: 'back.out',
      onStart: () => {
        this.flags.isTransitioning = true
      },
      onUpdate: () => {
        this.path = `
          M ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.878906, 0.898438, this.params.progress)}  
          C ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.9375, 0.910156, this.params.progress)} ${MathUtils.lerp(0.0234375, 0.0078125, this.params.progress)} ${MathUtils.lerp(1, 0.921875, this.params.progress)} ${MathUtils.lerp(0.0546875, 0.0117188, this.params.progress)} ${MathUtils.lerp(1, 0.925781, this.params.progress)}
          C ${MathUtils.lerp(0.199219, 0.171875, this.params.progress)} ${MathUtils.lerp(1, 0.976562, this.params.progress)} ${MathUtils.lerp(0.347656, 0.335938, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.5, 0.5, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} 
          C ${MathUtils.lerp(0.652344, 0.664062, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.800781, 0.828125, this.params.progress)} ${MathUtils.lerp(1, 0.972656, this.params.progress)} ${MathUtils.lerp(0.945312, 0.988281, this.params.progress)} ${MathUtils.lerp(1, 0.925781, this.params.progress)}
          C ${MathUtils.lerp(0.976562, 1.144531, this.params.progress)} ${MathUtils.lerp(1, 0.878906, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.9375, 0.910156, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.878906, 0.898438, this.params.progress)} 
          L ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.121094, 0.101562, this.params.progress)}
          C ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.0625, 0.0898438, this.params.progress)} ${MathUtils.lerp(0.976562, 0.992188, this.params.progress)} ${MathUtils.lerp(0, 0.078125, this.params.progress)} ${MathUtils.lerp(0.945312, 0.988281, this.params.progress)} ${MathUtils.lerp(0, 0.0742188, this.params.progress)}
          C ${MathUtils.lerp(0.800781, 0.828125, this.params.progress)} ${MathUtils.lerp(0, 0.0273438, this.params.progress)} ${MathUtils.lerp(0.652344, 0.664062, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.5, 0.5, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} 
          C ${MathUtils.lerp(0.347656, 0.335938, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.199219, 0.171875, this.params.progress)} ${MathUtils.lerp(0, 0.0273438, this.params.progress)} ${MathUtils.lerp(0.0546875, 0.0117188, this.params.progress)} ${MathUtils.lerp(0, 0.0742188, this.params.progress)}
          C ${MathUtils.lerp(0.0234375, 0.00390625, this.params.progress)} ${MathUtils.lerp(0, 0.0742188, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.0625, 0.0898438, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.121094, 0.101562, this.params.progress)} 
          Z M ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.878906, 0.898438, this.params.progress)}
        `

        document.querySelector('#video-overlay__mask__path-visible').setAttribute('d', this.path)

        this.progressPath = `
          M ${MathUtils.lerp(0.08, 0.08, this.params.progress)} ${MathUtils.lerp(4.7, 0.49, this.params.progress)} 
          c ${MathUtils.lerp(16.25, 16.25, this.params.progress)} ${MathUtils.lerp(0.03, 2.78, this.params.progress)} ${MathUtils.lerp(32.95, 32.95, this.params.progress)} ${MathUtils.lerp(0.04, 4.25, this.params.progress)} ${MathUtils.lerp(50, 50, this.params.progress)} ${MathUtils.lerp(0.04, 4.25, this.params.progress)} 
          s ${MathUtils.lerp(33.75, 33.75, this.params.progress)} ${MathUtils.lerp(-0.01, -1.47, this.params.progress)} ${MathUtils.lerp(50, 50, this.params.progress)} ${MathUtils.lerp(-0.04, -4.25, this.params.progress)} 
        `

        document.querySelector('#video-overlay__progress__path').setAttribute('d', this.progressPath)

        this.progressPathLength = this.selectors.progressPath.getTotalLength()
      },
      onComplete: () => {
        this.flags.isTransitioning = false
      },
    })

    gsap.to(this.selectors.container, {
      width: '71%',
      duration: this.params.duration.open,
      ease: 'back.out',
    })

    gsap.to(this.selectors.background, {
      autoAlpha: 1,
      duration: this.params.duration.open / 3,
      // ease: 'expo.inOut',
    })

    gsap.to(this.selectors.play, {
      scale: 0,
      duration: this.params.duration.open / 4,
      ease: 'back.in',
    })

    gsap.to(this.selectors.closeBtn, {
      scale: 1,
      duration: this.params.duration.open / 2,
      delay: this.params.duration.open / 2,
      ease: 'back.out',
    })

    let videoFade = gsap.timeline()

    videoFade.to(this.selectors.video.thumbnail, {
      autoAlpha: 0,
      duration: this.params.duration.open / 2,
    })

    videoFade.to(this.selectors.video.player, {
      autoAlpha: 1,
      duration: this.params.duration.open / 2,
      onStart: () => {
        this.selectors.video.player.play()
      },
    })
  }

  handleClose() {
    if (this.flags.isTransitioning) return
    if (!this.flags.isOpened) return

    gsap.to(this.params, {
      progress: 0,
      duration: this.params.duration.close,
      ease: 'back.inOut',
      onStart: () => {
        this.flags.isTransitioning = true
      },
      onUpdate: () => {
        this.shapePath = `
          M ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.878906, 0.898438, this.params.progress)}  
          C ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.9375, 0.910156, this.params.progress)} ${MathUtils.lerp(0.0234375, 0.0078125, this.params.progress)} ${MathUtils.lerp(1, 0.921875, this.params.progress)} ${MathUtils.lerp(0.0546875, 0.0117188, this.params.progress)} ${MathUtils.lerp(1, 0.925781, this.params.progress)}
          C ${MathUtils.lerp(0.199219, 0.171875, this.params.progress)} ${MathUtils.lerp(1, 0.976562, this.params.progress)} ${MathUtils.lerp(0.347656, 0.335938, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.5, 0.5, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} 
          C ${MathUtils.lerp(0.652344, 0.664062, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.800781, 0.828125, this.params.progress)} ${MathUtils.lerp(1, 0.972656, this.params.progress)} ${MathUtils.lerp(0.945312, 0.988281, this.params.progress)} ${MathUtils.lerp(1, 0.925781, this.params.progress)}
          C ${MathUtils.lerp(0.976562, 1.144531, this.params.progress)} ${MathUtils.lerp(1, 0.878906, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.9375, 0.910156, this.params.progress)} ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.878906, 0.898438, this.params.progress)} 
          L ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.121094, 0.101562, this.params.progress)}
          C ${MathUtils.lerp(1, 1, this.params.progress)} ${MathUtils.lerp(0.0625, 0.0898438, this.params.progress)} ${MathUtils.lerp(0.976562, 0.992188, this.params.progress)} ${MathUtils.lerp(0, 0.078125, this.params.progress)} ${MathUtils.lerp(0.945312, 0.988281, this.params.progress)} ${MathUtils.lerp(0, 0.0742188, this.params.progress)}
          C ${MathUtils.lerp(0.800781, 0.828125, this.params.progress)} ${MathUtils.lerp(0, 0.0273438, this.params.progress)} ${MathUtils.lerp(0.652344, 0.664062, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.5, 0.5, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} 
          C ${MathUtils.lerp(0.347656, 0.335938, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.199219, 0.171875, this.params.progress)} ${MathUtils.lerp(0, 0.0273438, this.params.progress)} ${MathUtils.lerp(0.0546875, 0.0117188, this.params.progress)} ${MathUtils.lerp(0, 0.0742188, this.params.progress)}
          C ${MathUtils.lerp(0.0234375, 0.00390625, this.params.progress)} ${MathUtils.lerp(0, 0.0742188, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.0625, 0.0898438, this.params.progress)} ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.121094, 0.101562, this.params.progress)} 
          Z M ${MathUtils.lerp(0, 0, this.params.progress)} ${MathUtils.lerp(0.878906, 0.898438, this.params.progress)}
        `

        document.querySelector('#video-overlay__mask__path-visible').setAttribute('d', this.shapePath)

        this.progressPath = `
          M ${MathUtils.lerp(0.08, 0.08, this.params.progress)} ${MathUtils.lerp(4.7, 0.49, this.params.progress)} 
          c ${MathUtils.lerp(16.25, 16.25, this.params.progress)} ${MathUtils.lerp(0.03, 2.78, this.params.progress)} ${MathUtils.lerp(32.95, 32.95, this.params.progress)} ${MathUtils.lerp(0.04, 4.25, this.params.progress)} ${MathUtils.lerp(50, 50, this.params.progress)} ${MathUtils.lerp(0.04, 4.25, this.params.progress)} 
          s ${MathUtils.lerp(33.75, 33.75, this.params.progress)} ${MathUtils.lerp(-0.01, -1.47, this.params.progress)} ${MathUtils.lerp(50, 50, this.params.progress)} ${MathUtils.lerp(-0.04, -4.25, this.params.progress)} 
        `

        document.querySelector('#video-overlay__progress__path').setAttribute('d', this.progressPath)

        this.progressPathLength = this.selectors.progressPath.getTotalLength()
      },
      onComplete: () => {
        this.flags.isTransitioning = false
        this.flags.isOpened = false
      },
    })

    gsap.to(this.selectors.container, {
      width: '23.3rem',
      duration: this.params.duration.close,
      ease: 'back.inOut',
    })

    gsap.to(this.selectors.background, {
      autoAlpha: 0,
      duration: this.params.duration.close * 1.5,
      ease: 'expo.inOut',
    })

    gsap.to(this.selectors.play, {
      scale: 1,
      duration: this.params.duration.close / 2,
      delay: this.params.duration.close * 1.5,
      ease: 'back.out',
    })

    gsap.to(this.selectors.closeBtn, {
      scale: 0,
      duration: this.params.duration.close / 2,
      // delay: this.params.duration.open / 2,
      ease: 'back.in',
    })

    let videoFade = gsap.timeline()

    videoFade.to(this.selectors.video.player, {
      autoAlpha: 0,
      duration: this.params.duration.close / 2,
      onComplete: () => {
        this.selectors.video.player.pause()
      },
    })

    videoFade.to(this.selectors.video.thumbnail, {
      autoAlpha: 1,
      duration: this.params.duration.close / 2,
    })

    this.selectors.pause.style.visibility = 'hidden'
  }

  update() {
    this.selectors.container.style.transform = `
      translate(
        ${MathUtils.lerp(this.gl.mouse.default.eased.current.x, this.gl.sizes.width / 2 - this.selectors.container.clientWidth / 2 + this.gl.mouse.normalized.eased.current.x * 25, this.params.progress)}px, 
        ${MathUtils.lerp(this.gl.mouse.default.eased.current.y, this.gl.sizes.height / 2 - this.selectors.container.clientHeight / 2 + this.gl.mouse.normalized.eased.current.y * -25, this.params.progress)}px) 
      rotate(${-1 * this.gl.mouse.normalized.eased.paceSeparated.y * 75 * (1 - this.params.progress * 0.9)}deg) 
      skewX(${this.gl.mouse.normalized.eased.paceSeparated.x * 150 * (1 - this.params.progress * 0.9)}deg)
    `

    if (this.isPreviousHover != this.gl.world.logo.flags.isHovering) {
      if (!this.gl.world.logo.flags.isHovering) {
        /* 
          Leave
        */
        gsap.to(this.selectors.scale, {
          scale: 0,
          duration: 0.3,
          ease: 'back.in',
        })
      } else {
        /* 
          Enter
        */
        gsap.to(this.selectors.scale, {
          scale: 1,
          duration: 0.3,
          ease: 'back.out',
        })
      }
    }

    this.isPreviousHover = this.gl.world.logo.flags.isHovering

    /* 
      Set Progress
    */
    this.normalizedPercent = this.selectors.video.player.currentTime / this.selectors.video.player.duration
    this.selectors.progressPath.style.strokeDashoffset = this.progressPathLength - this.progressPathLength * this.normalizedPercent * this.params.progress

    let progressCirclePosition = this.selectors.progressPath.getPointAtLength(this.progressPathLength * this.normalizedPercent)
    this.selectors.progressCircle.group.setAttribute('transform', `translate(${progressCirclePosition.x}, ${progressCirclePosition.y}) scale(${this.params.progress * 0.1})`)
  }
}
