export default class Mathematics {
  constructor() {}

  getAngleDegrees(x, y) {
    // Get the angle in radians using atan2
    let radians = Math.atan2(y, x)

    // Convert radians to degrees
    let degrees = radians * (180 / Math.PI)

    // Ensure the result is in the range [0, 360]
    if (degrees < 0) {
      degrees += 360
    }

    return degrees
  }
}
