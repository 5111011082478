import * as THREE from 'three'

import Gl from '../Gl'

import Plane from './Geometry/Plane'
// import Helmet from './Geometry/HelmetVer02'
import FullBody from './Geometry/FullBody'
import Logo from './Geometry/Logo'
import Lighting from './Lighting/Lighting'
import Environment from './Environment/Environment'

export default class World {
  constructor() {
    this.gl = new Gl()
    // this.plane = new Plane()
    // this.helmet = new Helmet()

    /* 
      Animation Group
    */
    this.animationGroup = new THREE.Group()
    // this.animationGroup.frustumCulled = false

    // Scale
    this.animationGroup.scale.set(10, 10, 10)

    // Rotation
    // this.animationGroup.rotation.y = Math.PI

    // Position
    // this.animationGroup.position.y = -5

    this.gl.scene.add(this.animationGroup)

    /* 
      Geometry
    */
    this.fullBody = new FullBody(this.animationGroup)
    this.lighting = new Lighting(this.animationGroup)
    this.logo = new Logo()
    this.environment = new Environment()
  }

  resize() {
    this.logo.resize()
  }

  update() {
    // this.plane.update()
    this.fullBody.update()
    this.logo.update()
  }
}
