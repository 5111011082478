import { Vector2, MathUtils } from 'three'

import Gl from '../Gl'

export default class Mouse {
  constructor() {
    this.gl = new Gl()

    // Default
    this.default = {
      current: new Vector2(),
      eased: {
        current: new Vector2(),
        intesity: 0.005,
      },
    }

    // Normalized
    this.normalized = {
      current: new Vector2(),
      previous: new Vector2(),
      direction: new Vector2(),
      pace: 0,
      paceSeparated: new Vector2(),
      eased: {
        current: new Vector2(),
        previous: new Vector2(),
        direction: new Vector2(),
        intesity: 0.0025,
        pace: 0,
        paceSeparated: new Vector2(),
        paceIntensity: 0.005,
      },
    }

    // Mouse Hold
    this.isMouseHolding = false

    // Drag
    this.drag = {
      start: new Vector2(),
      distance: new Vector2(),
      side: 'left',
    }

    // // // // // // // // // // // // // // // //
    // EVENTS

    // Move
    document.addEventListener('mousemove', this.mousemove.bind(this))
    document.addEventListener('touchmove', this.touchmove.bind(this))

    // Down
    document.addEventListener('mousedown', this.down.bind(this))
    document.addEventListener('touchstart', this.down.bind(this))

    // Up
    document.addEventListener('mouseup', this.up.bind(this))
    document.addEventListener('touchend', this.up.bind(this))
  }

  mousemove(_event) {
    // Set Default
    this.default.current.x = _event.clientX
    this.default.current.y = _event.clientY

    // Set Normalized
    this.normalized.current.x = (_event.clientX / this.gl.sizes.width) * 2 - 1
    this.normalized.current.y = -(_event.clientY / this.gl.sizes.height) * 2 + 1

    // Set Direction
    this.normalized.direction.subVectors(this.normalized.current, this.normalized.previous).normalize()

    if (this.isMouseHolding) {
      // Set Drag Distance
      this.drag.distance = this.drag.start.distanceTo(this.default.current)

      if (this.drag.start.x < this.default.current.x) {
        this.drag.side = 'right'
      } else {
        this.drag.side = 'left'
      }
    } else {
      this.drag.distance = 0
    }
  }

  touchmove(_event) {
    if (_event.touches) {
      // Set Normalized
      this.normalized.current.x = (_event.touches[0].pageX / this.gl.sizes.width) * 2 - 1
      this.normalized.current.y = -(_event.touches[0].pageY / this.gl.sizes.height) * 2 + 1
    }
  }

  down(_event) {
    this.isMouseHolding = true

    // Reset Drag Distance
    this.drag.start.copy(this.default.current)

    // Set for mobile
    if (_event.touches) {
      // Set Normalized
      this.normalized.current.x = (_event.touches[0].pageX / this.gl.sizes.width) * 2 - 1
      this.normalized.current.y = -(_event.touches[0].pageY / this.gl.sizes.height) * 2 + 1
    }
  }

  up(_event) {
    this.isMouseHolding = false
  }

  update() {
    // Set Pace
    this.normalized.pace = this.normalized.current.distanceTo(this.normalized.previous)
    this.normalized.paceSeparated.x = this.normalized.current.x - this.normalized.previous.x
    this.normalized.paceSeparated.y = this.normalized.current.y - this.normalized.previous.y

    // Set Previous
    this.normalized.previous.copy(this.normalized.current)

    // Set default eased
    // this.default.eased.current.lerp(this.default.current, this.default.eased.intesity)

    this.default.eased.current.x = MathUtils.damp(this.default.eased.current.x, this.default.current.x, this.default.eased.intesity, this.gl.time.delta)
    this.default.eased.current.y = MathUtils.damp(this.default.eased.current.y, this.default.current.y, this.default.eased.intesity, this.gl.time.delta)

    // Set normalized eased
    this.normalized.eased.previous.copy(this.normalized.eased.current)

    this.normalized.eased.current.x = MathUtils.damp(this.normalized.eased.current.x, this.normalized.current.x, this.normalized.eased.intesity, this.gl.time.delta)
    this.normalized.eased.current.y = MathUtils.damp(this.normalized.eased.current.y, this.normalized.current.y, this.normalized.eased.intesity, this.gl.time.delta)

    // Set eased direction
    this.normalized.eased.direction.subVectors(this.normalized.eased.current, this.normalized.eased.previous).normalize()

    // Set eased pace
    this.normalized.eased.pace = MathUtils.damp(this.normalized.eased.pace, this.normalized.pace, this.normalized.eased.paceIntensity, this.gl.time.delta)
    this.normalized.eased.paceSeparated.x = MathUtils.damp(this.normalized.eased.paceSeparated.x, this.normalized.paceSeparated.x, this.normalized.eased.paceIntensity, this.gl.time.delta)
    this.normalized.eased.paceSeparated.y = MathUtils.damp(this.normalized.eased.paceSeparated.y, this.normalized.paceSeparated.y, this.normalized.eased.paceIntensity, this.gl.time.delta)
  }
}
