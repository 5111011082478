import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import Gl from './Gl'

export default class Camera {
  constructor() {
    this.gl = new Gl()

    // this.data = {
    //   lookAt: {
    //     from: new THREE.Vector3(0, -0.25, 0),
    //     to: new THREE.Vector3(0, 0, 0),
    //     current: new THREE.Vector3(0, 0, 0),
    //   },
    // }

    this.instance = new THREE.PerspectiveCamera(24, this.gl.sizes.width / this.gl.sizes.height, 0.01, 1000)
    this.instance.position.z = 6

    // this.setOrbitControls()
  }

  resize() {
    this.instance.aspect = this.gl.sizes.width / this.gl.sizes.height
    this.instance.updateProjectionMatrix()
  }

  setOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.gl.canvas)
    this.controls.enableDamping = true
  }
}
