import * as THREE from 'three'
// import { RectAreaLightHelper } from 'three/addons/helpers/RectAreaLightHelper.js'
// import { RectAreaLightUniformsLib } from 'three/addons/lights/RectAreaLightUniformsLib.js'

import Gl from '../../Gl'

export default class Lighting {
  constructor(_animationGroup) {
    this.gl = new Gl()

    // RectAreaLightUniformsLib.init()

    /* 
      Hemisphere
    */
    this.hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 1)

    this.gl.scene.add(this.hemisphereLight)

    /* 
      Rect Area Light
    */
    // this.width = 0.1
    // this.height = 0.1
    // this.intensity = 1
    // this.rectLight = new THREE.RectAreaLight(0xffffff, this.intensity, this.width, this.height)
    // this.rectLight.position.z = 0.05
    // this.rectLight.position.y = 0.4
    // this.rectLight.lookAt(0, 0, 0)
    // this.gl.scene.add(this.rectLight)

    // this.rectLightHelper = new RectAreaLightHelper(this.rectLight)
    // this.rectLight.add(this.rectLightHelper)

    /* 
      Ambient
    */
    this.sphereSize = 0.01

    this.pointLightL = new THREE.PointLight(0xd2ff00, 3, 0, 5)
    this.pointLightL.position.x = -0.05
    this.pointLightL.position.z = 0.05
    this.pointLightL.position.y = 0.425
    _animationGroup.add(this.pointLightL)

    // this.pointLightR = new THREE.PointLight(0xd2ff00, 1, 0, 1)
    // this.pointLightR.position.x = 0.075
    // this.pointLightR.position.z = 0.05
    // this.pointLightR.position.y = 0.45
    // _animationGroup.add(this.pointLightR)
  }
}
