import * as THREE from 'three'

import Camera from './Camera'
import Renderer from './Renderer'

import World from './World/World'

import Time from './Utils/Time'
import Sizes from './Utils/Sizes'
import Debug from './Utils/Debug'
import Mouse from './Utils/Mouse'

import Assets from './Assets/Assets'
import Animations from './Animations/Animations'
import Video from './Animations/Video'

let instance = null

export default class Gl {
  constructor(_canvas) {
    // Singleton
    if (instance) {
      return instance
    }

    instance = this

    // Flags
    this.isLoaded = false

    // Canvas
    this.canvas = _canvas

    // Utils
    this.time = new Time()
    this.sizes = new Sizes()
    this.debug = new Debug()
    this.mouse = new Mouse()

    // Default
    this.scene = new THREE.Scene()
    this.camera = new Camera()
    this.renderer = new Renderer()

    // Loading Manager
    this.loadingManager = new THREE.LoadingManager()
    this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      console.log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.')
    }
    this.loadingManager.onLoad = () => {
      this.world = new World()
      this.animations = new Animations()
      this.video = new Video()

      this.isLoaded = true
      // this.debug.addGUI()
    }

    // Assets
    this.assets = new Assets()

    // Update
    this.time.on('tick', () => {
      this.update()
    })

    // Resize
    this.sizes.on('resize', () => {
      this.resize()
    })
  }

  update() {
    if (this.debug.isDebug) this.debug.stats.begin()

    this.renderer.update()
    this.mouse.update()

    if (this.isLoaded) {
      this.world.update()
      this.video.update()
    }

    if (this.debug.isDebug) this.debug.stats.end()
  }

  resize() {
    this.camera.resize()
    this.renderer.resize()

    if (this.isLoaded) {
      this.world.resize()
    }
  }
}
