import * as THREE from 'three'

import Gl from '../../Gl'

export default class FullBody {
  constructor(_animationGroup) {
    this.gl = new Gl()

    this.glslIncludes = {
      noise: `
        //	Simplex 4D Noise 
        //	by Ian McEwan, Stefan Gustavson (https://github.com/stegu/webgl-noise)
        //
        vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
        float permute(float x){return floor(mod(((x*34.0)+1.0)*x, 289.0));}
        vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
        float taylorInvSqrt(float r){return 1.79284291400159 - 0.85373472095314 * r;}

        vec4 grad4(float j, vec4 ip){
          const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
          vec4 p,s;

          p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
          p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
          s = vec4(lessThan(p, vec4(0.0)));
          p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www; 

          return p;
        }

        float snoise(vec4 v){
          const vec2  C = vec2( 0.138196601125010504,  // (5 - sqrt(5))/20  G4
                                0.309016994374947451); // (sqrt(5) - 1)/4   F4
        // First corner
          vec4 i  = floor(v + dot(v, C.yyyy) );
          vec4 x0 = v -   i + dot(i, C.xxxx);

        // Other corners

        // Rank sorting originally contributed by Bill Licea-Kane, AMD (formerly ATI)
          vec4 i0;

          vec3 isX = step( x0.yzw, x0.xxx );
          vec3 isYZ = step( x0.zww, x0.yyz );
        //  i0.x = dot( isX, vec3( 1.0 ) );
          i0.x = isX.x + isX.y + isX.z;
          i0.yzw = 1.0 - isX;

        //  i0.y += dot( isYZ.xy, vec2( 1.0 ) );
          i0.y += isYZ.x + isYZ.y;
          i0.zw += 1.0 - isYZ.xy;

          i0.z += isYZ.z;
          i0.w += 1.0 - isYZ.z;

          // i0 now contains the unique values 0,1,2,3 in each channel
          vec4 i3 = clamp( i0, 0.0, 1.0 );
          vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
          vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );

          //  x0 = x0 - 0.0 + 0.0 * C 
          vec4 x1 = x0 - i1 + 1.0 * C.xxxx;
          vec4 x2 = x0 - i2 + 2.0 * C.xxxx;
          vec4 x3 = x0 - i3 + 3.0 * C.xxxx;
          vec4 x4 = x0 - 1.0 + 4.0 * C.xxxx;

        // Permutations
          i = mod(i, 289.0); 
          float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
          vec4 j1 = permute( permute( permute( permute (
                    i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
                  + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
                  + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
                  + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));
        // Gradients
        // ( 7*7*6 points uniformly over a cube, mapped onto a 4-octahedron.)
        // 7*7*6 = 294, which is close to the ring size 17*17 = 289.

          vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;

          vec4 p0 = grad4(j0,   ip);
          vec4 p1 = grad4(j1.x, ip);
          vec4 p2 = grad4(j1.y, ip);
          vec4 p3 = grad4(j1.z, ip);
          vec4 p4 = grad4(j1.w, ip);

        // Normalise gradients
          vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
          p0 *= norm.x;
          p1 *= norm.y;
          p2 *= norm.z;
          p3 *= norm.w;
          p4 *= taylorInvSqrt(dot(p4,p4));

        // Mix contributions from the five corners
          vec3 m0 = max(0.6 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0);
          vec2 m1 = max(0.6 - vec2(dot(x3,x3), dot(x4,x4)            ), 0.0);
          m0 = m0 * m0;
          m1 = m1 * m1;
          return 49.0 * ( dot(m0*m0, vec3( dot( p0, x0 ), dot( p1, x1 ), dot( p2, x2 )))
                      + dot(m1*m1, vec2( dot( p3, x3 ), dot( p4, x4 ) ) ) ) ;

        }
      `,
    }

    /* 
      Uniforms
    */
    this.uniforms = {
      uTime: new THREE.Uniform(0),
      tMask: new THREE.Uniform(this.gl.assets.textures.helmet.mask),
    }

    /* 
      Animations
    */

    this.mixer = new THREE.AnimationMixer(this.gl.assets.models.fullBody.scene)
    this.animations = {
      idle: {
        animation: this.mixer.clipAction(this.gl.assets.models.fullBody.animations[0]),
        weight: 1,
      },
      victory: {
        animation: this.mixer.clipAction(this.gl.assets.models.fullBody.animations[1]),
        weight: 0,
      },
    }

    this.animations.idle.animation.play()
    this.animations.idle.animation.setEffectiveWeight(this.animations.idle.weight)

    this.animations.victory.animation.play()
    this.animations.victory.animation.setEffectiveWeight(this.animations.victory.weight)
    /* 
      Set Material
    */

    // Change texture on helmet
    this.gl.assets.models.fullBody.scene.children[0].children.forEach((_child) => {
      /* 
        Helmet Base
      */
      if (_child.name == 'a_helmet--base') {
        // _child.material = this.material

        _child.material = new THREE.MeshPhysicalMaterial({
          map: this.gl.assets.textures.helmet.base,
          roughnessMap: this.gl.assets.textures.helmet.roughness,
          normalMap: this.gl.assets.textures.helmet.normal,
          metalnessMap: this.gl.assets.textures.helmet.metallic,
          // metalness: 0.25,
          iridescence: 1,
          iridescenceIOR: 1.5,
          envMapIntensity: 1.35,
        })

        _child.material.onBeforeCompile = (_shader) => {
          // // // // // // // // // // // // // // // // // // // // // //
          // // // // // // // // // // // // // // // // // // // // // //
          // VERTEX
          _shader.vertexShader = _shader.vertexShader.replace(
            'varying vec3 vViewPosition;',
            `
              varying vec3 vViewPosition;
              varying vec3 vLocalPosition;
              varying vec3 vWorldPosition;
              varying vec2 vUv;
            `
          )

          _shader.vertexShader = _shader.vertexShader.replace(
            '#include <fog_vertex>',
            `
              vLocalPosition = position.xyz;
              vWorldPosition = worldPosition.xyz;
              vUv = uv;
            `
          )

          // // // // // // // // // // // // // // // // // // // // // //
          // // // // // // // // // // // // // // // // // // // // // //
          // FRAGMENT
          _shader.uniforms.uTime = this.uniforms.uTime
          _shader.uniforms.tMask = this.uniforms.tMask

          _shader.fragmentShader = _shader.fragmentShader.replace(
            '#include <common>',
            `
              ${this.glslIncludes.noise}

              #define M_PI 3.14159265358979323846

              const float DISTORT_SCALE = 50.0;
              const float DISTORT_INTENSITY = 0.006; // 0.0075

              const float NOISE_SIZE = 50.0;

              const float OUTLINE_SCALE = 0.125;
              const float OUTLINE_THICKNESS = 0.2;

              const float TIME_SPEED = 0.00025;

              varying vec3 vWorldPosition;
              varying vec3 vLocalPosition;
              varying vec2 vUv;

              uniform sampler2D tMask;
              uniform float uTime;

              #include <common>
          `
          )

          _shader.fragmentShader = _shader.fragmentShader.replace(
            'vec4 diffuseColor = vec4( diffuse, opacity );',
            `
              vec4 textureMask = texture2D(tMask, vUv);
              float animationPace = uTime * TIME_SPEED;

              float noise = 0.5 - snoise(
                vec4(
                  vLocalPosition.x * NOISE_SIZE, 
                  vLocalPosition.y * NOISE_SIZE, 
                  // vLocalPosition.z * NOISE_SIZE / max(vLocalPosition.y * -1.0 * 100., 1.0), 
                  vLocalPosition.z * NOISE_SIZE, 
                  animationPace
                )
              ) * 0.5;

              noise *= 4.0;
              noise = fract(noise);
              // noise = smoothstep(0.49, 0.51, noise);
              noise = step(0.5, noise);

              vec3 diffuseTexture = diffuse;
              diffuseTexture -= noise;
              diffuseTexture = clamp(diffuseTexture, 0.0, 1.0);
              diffuseTexture += 1.0 - textureMask.r;
              diffuseTexture = clamp(diffuseTexture, 0.0, 1.0);

              vec4 diffuseColor = vec4( diffuseTexture, opacity );
              // vec4 diffuseColor = vec4(max(vec3(vWorldPosition.y * -1.0) * 100., 1.0), opacity );
          `
          )
        }
      } else if (_child.name == 'a_helmet--glass') {
        /* 
          Helmet Glass
        */
        _child.material = new THREE.MeshPhysicalMaterial({
          map: this.gl.assets.textures.glass.base,
          roughnessMap: this.gl.assets.textures.glass.roughness,
          normalMap: this.gl.assets.textures.glass.normal,
          metalnessMap: this.gl.assets.textures.helmet.metallic,
          // metalness: 0.5,
          iridescence: 1,
          iridescenceIOR: 1.5,
          // envMapIntensity: 1.35,
        })
      } else if (_child.name == 'a_helmet--plastic') {
        /* 
          Helmet Plastic
        */
        _child.material = new THREE.MeshMatcapMaterial({
          transparent: true,
          opacity: 0.5,
          matcap: this.gl.assets.textures.plastic.matcap,
          side: THREE.DoubleSide,
        })
      } else {
        /* 
          Rest
        */
        _child.material = new THREE.MeshPhysicalMaterial({
          color: 0x000000,
          roughness: 0.5,
          // metalness: 0.5,
          iridescence: 1,
          iridescenceIOR: 1.5,
          side: THREE.DoubleSide,
          // envMapIntensity: 1.35,
        })
      }
    })

    _animationGroup.add(this.gl.assets.models.fullBody.scene.children[0])
  }

  update() {
    this.uniforms.uTime.value = this.gl.time.elapsed

    this.mixer.update(this.gl.time.delta * 0.001)
  }
}
