import * as THREE from 'three'

import logoVertex from '../../Shaders/Vertex/logoVertex.glsl'
import logoFragment from '../../Shaders/Fragment/logoFragment02.glsl'

import Gl from '../../Gl'

export default class Logo {
  constructor() {
    this.gl = new Gl()

    /* 
      Params
    */
    this.params = {
      parallax: 0.5,
    }

    /* 
      Flags
    */
    this.flags = {
      isHovering: false,
    }

    /* 
      Set Mesh
    */
    this.geometry = new THREE.PlaneGeometry(1, 1)
    this.material = new THREE.ShaderMaterial({
      //
      fragmentShader: logoFragment,
      vertexShader: logoVertex,
      // side: THREE.DoubleSide,
      transparent: true,
      uniforms: {
        tAlpha: new THREE.Uniform(this.gl.assets.textures.logo.alpha),
        uMouse: new THREE.Uniform(new THREE.Vector2()),
        uPace: new THREE.Uniform(0),
        uTime: new THREE.Uniform(0),
      },
    })

    this.instance = new THREE.Mesh(this.geometry, this.material)
    this.instance.position.y = 0.05

    /* 
      Get Sizes
    */
    this.cameraViewSize = new THREE.Vector2()
    this.gl.camera.instance.getViewSize(this.gl.camera.instance.position.z, this.cameraViewSize)

    this.instance.scale.set(this.cameraViewSize.width, this.cameraViewSize.width)

    this.gl.scene.add(this.instance)

    /* 
      Raycaster
    */
    this.raycaster = new THREE.Raycaster()
    this.intersected = null
    // this.intersect
  }

  resize() {
    this.gl.camera.instance.getViewSize(this.gl.camera.instance.position.z, this.cameraViewSize)

    this.instance.scale.set(this.cameraViewSize.width, this.cameraViewSize.width)
  }

  update() {
    /* 
      Raycaset
    */
    this.raycaster.setFromCamera(
      {
        //
        x: this.gl.mouse.normalized.eased.current.x,
        y: this.gl.mouse.normalized.eased.current.y,
      },
      this.gl.camera.instance
    )
    this.intersected = this.raycaster.intersectObject(this.instance)

    if (this.intersected.length) {
      this.material.uniforms.uMouse.value.x = 0.5 + this.intersected[0].point.x / this.cameraViewSize.width
      this.material.uniforms.uMouse.value.y = 0.5 + this.intersected[0].point.y / this.cameraViewSize.width

      this.flags.isHovering = true
      // console.log(this.material.uniforms.uMouse.value.x)
    } else {
      this.flags.isHovering = false
    }

    /* 
      Other Uniforms
    */
    this.material.uniforms.uPace.value = this.gl.mouse.normalized.eased.pace
    this.material.uniforms.uTime.value = this.gl.time.elapsed * 0.0002

    /* 
      Set Scroll
    */
    this.gl.world.logo.instance.position.y = (window.scrollY / this.gl.sizes.height) * this.gl.world.logo.cameraViewSize.height * this.params.parallax
  }
}
