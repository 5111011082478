import * as THREE from 'three'

import Gl from './Gl'

export default class Rendered {
  constructor() {
    this.gl = new Gl()

    this.instance = new THREE.WebGLRenderer({
      canvas: this.gl.canvas,
      antialias: true,
      alpha: true,
    })

    this.instance.setPixelRatio(window.devicePixelRatio)
    this.instance.setSize(this.gl.sizes.width, this.gl.sizes.height)
    // this.instance.setClearColor(0xd2ff00)
    // this.instance.outputColorSpace = THREE.SRGBColorSpace
  }

  update() {
    this.instance.render(this.gl.scene, this.gl.camera.instance)
  }

  resize() {
    this.instance.setSize(this.gl.sizes.width, this.gl.sizes.height)
    this.instance.setPixelRatio(this.gl.sizes.pixelRatio)
  }
}
